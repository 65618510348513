// mini-sab
.mini-sab {
	background: $blue;

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -26px 0 15px 0;
			padding: 15px 0;
			border-radius: 6px;
			background: $white;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			@include media-breakpoint-up(lg) {
				margin-top: -66px;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}
