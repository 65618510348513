// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 15px;
	background: $white;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&::after {
		content: url('/images/header-bg.svg');
		z-index: -1;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		font-size: 0;
		line-height: 0;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			margin-right: 5px;
			padding: 0;
			border: none;
			color: $blue-dark;
			font-size: 24px;
			transition: color 0.5s;

			i {
				width: 30px;
				font-size: 24px;
				line-height: 40px;
				text-align: center;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin-right: auto;

		@include media-breakpoint-only(lg) {
			width: 250px;
			margin-bottom: -15px;
		}

		@include media-breakpoint-up(xl) {
			width: 300px;
			margin-bottom: -30px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;
						font-size: 16px;

						&:hover {
							color: $blue-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $blue-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $black;

								&:hover {
									color: $blue-dark;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $blue-dark;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 15px;

				>.nav-item {
					margin-left: 10px;
					font-size: 15px;
					font-weight: 600;

					>.nav-link {
						color: $black;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-radius: 4px;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $blue-dark;
								}
							}

							&.active {
								.nav-link {
									color: $blue-dark;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-secondary;
					min-width: auto !important;
					padding: 0.375rem 1rem !important;

					i {
						@include media-breakpoint-up(sm) {
							display: none;
						}
					}

					.list-item-title {
						@include media-breakpoint-down(xs) {
							display: none;
						}

						@include media-breakpoint-down(lg) {
							font-size: 15px;
						}
					}

					&:hover {
						background-color: $yellow;
						border-color: $yellow;
						color: $black;
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
