// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	&::after {
		content: url('/images/eyecatcher-bg-bottom.svg');
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	.owl-carousel {
		.item {
			align-items: flex-end;
			padding-bottom: 100px;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				max-height: 400px;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 960px;

					.owl-caption {
						text-shadow: 6px 6px 12px rgba($black, 0.15);

						.owl-title {
							margin-bottom: 0;
							font-family: $font-family-tertiary;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 48px;
							}
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}
}
