// bode
& {
	background: rgba($green, 0.15);
}

// headings
h5,
.h5 {
	color: $blue;
	font-family: $font-family-tertiary;
}

// container
.container {
	@include media-breakpoint-up(xl) {
		max-width: 1210px;
	}

	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.card {
	background-color: unset;
}

// default-card
.default-card {
	border-radius: 0;
	border: none;

	.card-image {
		border-radius: 10px;
	}

	.card-body {
		padding: 15px 0 0 0;

		.card-caption {
			.card-title {
				color: $blue-dark;
			}

			.card-subtitle {
				color: $blue;
			}
		}
	}
}

// tiles-collection
.tiles-collection {
	&.grid {
		.grid-items {
			margin: 0 -10px -20px -10px;

			.item {
				margin-bottom: 20px;
				padding-left: 10px;
				padding-right: 10px;

				@include media-breakpoint-up(lg) {
					@include make-col(3);
				}

				.card {
					border-radius: 10px;
					border: none;

					.card-img-overlay {
						top: auto;
						padding: 0;
						background: none;

						.card-caption {
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							margin-top: auto;
							padding: 25px;
							background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
							text-align: center;

							.card-title,
							.card-subtitle {
								margin-bottom: 0.5rem;
								font-family: $font-family-tertiary;
							}

							.card-subtitle,
							.card-description {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// icon-collection
.icon-collection {
	&.grid {
		.grid-items {
			margin: 0 -10px -20px -10px;

			.item {
				margin-bottom: 20px;
				padding-left: 10px;
				padding-right: 10px;

				.card {
					border: none;

					.card-image {
						color: $blue;
						font-size: 36px;
						line-height: 1;

						.card-image-link {
							color: inherit;
						}
					}

					.card-body {
						text-align: center;

						.card-caption {
							.card-title {
								color: $blue-dark;
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 10px;
		}
	}
}

// modal
&.modal-open {
	overflow: visible;
	padding-right: 0 !important;

	.modal {
		overflow: visible;
	}
}

.modal-backdrop {
	display: none;
}

.modal {
	top: 20%;
	left: auto;
	right: 0;
	bottom: auto;
	width: auto;
	height: auto;
	padding-right: 0 !important;
	overflow: visible;

	.modal-dialog {
		margin: 0 !important;
		max-width: 250px;

		&::before {
			content: '';
			z-index: 10;
			position: absolute;
			top: 50%;
			left: -22px;
			transform: translateY(-50%);
			width: 57px;
			height: 81px;
			background: url('/images/modal-arrow.svg');
		}

		.modal-content {
			border: none;
			border-radius: 10px 0 0 10px;
			background: $blue-dark;

			.modal-header {
				display: block;
				border-bottom: 0;
				padding-bottom: 0;
				text-align: center;

				.modal-title {
					color: $white;
					font-size: 24px;
				}

				.close {
					z-index: 10;
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					width: 30px;
					height: 30px;
					margin: 0;
					padding: 0;
					color: $white;
					font-size: 24px;
					font-family: sans-serif;
					opacity: 1;
					text-shadow: none;
					transition: 0.5s;

					&:hover {
						color: $yellow;
					}
				}
			}

			.modal-body {
				padding-top: 0;
				color: $white;
				font-size: 14px;
				text-align: center;

				a:not(.btn) {
					color: $white;
					text-decoration: underline;

					&:hover {
						color: $yellow;
					}
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: $white;
				}

				p:last-of-type {
					margin-bottom: 0;
				}

				.btn {
					font-size: 16px;
				}
			}
		}
	}
}
