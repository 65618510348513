// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$blue: #009ddd;
$blue-light: #bfe6f6;
$blue-dark: #005fac;
$yellow: #ffd400;
$green: #268133;
$gray-dark: #161615;

$white: #fff;
$black: #000;

// theme-colors
$primary: $yellow;
$secondary: $blue-dark;

// body
$body-bg: $white;
$body-color: $gray-dark;

// links
$link-color: $secondary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Itim&family=Lilita+One&display=swap');
$font-family-primary: 'Inter', sans-serif;
$font-family-secondary: 'Lilita One', cursive;
$font-family-tertiary: 'Itim', cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $blue-dark;

// contextual
$light: $blue-light;
$dark: $blue-dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

// card
$card-border-radius: 10px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
