// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// usps-section
// =========================================================================
.usps-section {
	position: relative;
	background: $blue;

	// global-usps
	div.global-usps {
		ul {
			justify-content: center;

			li {
				margin: 5px 15px;
				color: $white;
				font-size: 18px;
				font-family: $font-family-secondary;

				@include media-breakpoint-up(xl) {
					margin: 5px 30px;
				}

				&::before {
					content: '\f058';
					margin-right: 5px;
					color: $white;
					font-size: 16px;
					font-weight: 400;
					font-family: $font-awesome;
				}

				a {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	margin-bottom: 6vh;
	padding-top: calc(7.5vw + 30px);
	text-align: center;

	&::before {
		content: url('/images/lead-section-bg-top.svg');
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		max-width: 720px;
		margin: 0 auto;

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// discover-section
// =========================================================================
.discover-section {
	position: relative;
	margin: 6vh 0;
	padding-top: 120px;
	padding-bottom: 6vh;
	background: $blue-light;

	@include media-breakpoint-up(xl) {
		padding-top: calc(6vh + 120px);
	}

	&::before {
		content: url('/images/discover-bg-top.svg');
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
		background: #DEECE0;
	}

	&::after {
		content: url('/images/discover-bg-bottom.svg');
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	// collection
	.collection {
		z-index: 1;

		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-down(sm) {
						@include make-col(12);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					&:first-child {
						.card {
							background: transparent;
							border: none;
							border-radius: 0;
							word-wrap: unset;
							justify-content: center;

							.card-image {
								display: none;
							}

							.card-img-overlay {
								position: static;
								background: transparent;
								padding: 0;
								border-radius: 0;

								@include media-breakpoint-down(sm) {
									text-align: center;
								}

								.card-caption {
									color: $black;
									text-shadow: none;

									.card-title {
										color: $blue-dark;
										@include font-size($h2-font-size);
									}

									.card-subtitle {
										color: $blue;
										@extend .h5;
										@include font-size($h5-font-size);
									}
								}

								.card-buttons {
									.card-btn {
										@extend .btn-primary;
									}
								}
							}
						}
					}

					&:not(:first-child) {
						.card {
							border: none;
							border-radius: 10px;

							.card-img-overlay {
								display: flex;
								flex-direction: column;
								top: auto;
								padding: 15px;
								background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

								@include media-breakpoint-up(xl) {
									padding: 30px;
								}

								.card-caption {
									display: flex;
									align-items: center;
									flex-direction: column;
									justify-content: flex-end;
									text-shadow: none;
									text-align: center;

									.card-title {
										margin-bottom: 0;
										font-family: $font-family-tertiary;

										&:hover {
											color: inherit;
										}
									}

									.card-subtitle,
									.card-description {
										display: none;
									}
								}

								.card-buttons {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			background: transparent;
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
