// footer
.footer {
	position: relative;
	margin-top: 6vh;
	padding-top: 120px;
	background: $blue;
	color: $white;
	overflow: hidden;

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			color: $yellow;
		}
	}

	h1,
	.h1,
	h2,
	.h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	&::before {
		content: url('/images/footer-bg-top.svg');
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 0;
		line-height: 0;
	}

	.container {
		position: relative;
		z-index: 1;
	}

	// footer-outro
	.footer-outro {
		.container-holder {
			max-width: 660px;
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		margin: 6vh 0;

		.column {
			margin-bottom: 30px;
		}

		// footer-text
		.footer-text {
			font-size: 14px;

			a {

				&[href^='tel:'],
				&[href^='mailto:'] {
					position: relative;
					padding-left: 20px;
					text-decoration: underline;

					&::before {
						position: absolute;
						top: 0;
						left: 0;
						font-family: $font-awesome;
					}
				}

				&[href^='tel:'] {
					&::before {
						content: '\f095';
					}
				}

				&[href^='mailto:'] {
					&::before {
						content: '\f0e0';
					}
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 5px;
			}
		}
	}

	// footer-logolink
	.footer-logolink {
		ul {
			li {
				margin-right: 15px;
				font-size: 24px;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		position: relative;
		padding: 150px 0 30px 0;
		background: $blue-light;

		&::before {
			content: url('/images/footer-bg-bottom.svg');
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			font-size: 0;
			line-height: 0;
			background: $blue;

			@include media-breakpoint-down(md) {
				top: 0;
			}

			@include media-breakpoint-up(lg) {
				bottom: 50px;
			}
		}

		.container-two-columns {
			.container-holder {
				align-items: flex-end;

				.column {
					@include media-breakpoint-down(md) {
						@include make-col(12);
						align-items: center;

						&.two {
							order: -1;
							margin-bottom: 45px;
						}
					}
				}
			}
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-down(sm) {
				text-align: center;
				flex-direction: column;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 30px;
				color: $blue-dark;
				font-size: 14px;

				a {
					color: $blue-dark;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.logo {
			max-width: 300px;

			@include media-breakpoint-up(lg) {
				margin-left: auto
			}

			a {
				@extend .d-block;

				img {
					@extend .w-100;
				}
			}
		}
	}
}
